import React from "react";
import loadable from "@loadable/component";
import {
   BlockOutlined,
   HomeOutlined,
   SettingOutlined,
   BellOutlined,
   VideoCameraOutlined,
   CommentOutlined,
   FundProjectionScreenOutlined,
   CoffeeOutlined,
   CreditCardOutlined,
   ShoppingCartOutlined,
   PushpinOutlined,
   UserOutlined,
   QuestionCircleOutlined,
} from "@ant-design/icons";
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

export interface ISidebar {
   label: string;
   icon?: React.ReactNode;
}

export interface IRoute {
   exact?: boolean;
   visible?: boolean;
   path: string;
   component?: any;
   children?: Array<IRoute>;
   sidebar?: ISidebar;
}

export const publicRoutes: Array<IRoute> = [
   {
      exact: true,
      path: PUBLIC_ROUTE.ROOT,
      component: () => <Redirect to="/login" />,
   },
   {
      exact: true,
      path: PUBLIC_ROUTE.LOGIN,
      component: loadable(() => import("../pages/auth/Login")),
   },
];

interface IPrivateRoute extends IRoute {}

export const privateRoutes: Array<IPrivateRoute> = [
   {
      exact: true,
      path: "/basic",
      sidebar: {
         icon: <SettingOutlined />,
         label: "기본 설정",
      },
      children: [
         {
            exact: true,
            path: "/subadmin",
            sidebar: {
               label: "부운영자 리스트",
            },
            component: loadable(() => import("../pages/basic/subAdmin")),
         },
         {
            exact: true,
            path: "/subadmin/submit",
            visible: false,
            component: loadable(() => import("../pages/basic/subAdmin/Submit")),
         },
         {
            exact: true,
            path: "/terms",
            sidebar: {
               label: "약관 리스트",
            },
            component: loadable(() => import("../pages/basic/terms")),
         },
         {
            exact: true,
            path: "/terms/submit",
            visible: false,
            component: loadable(
               () => import("../pages/basic/terms/Submit.jsx")
            ),
         },
         {
            exact: true,
            path: "/prohibitive",
            sidebar: {
               label: "금지어 관리",
            },
            component: loadable(() => import("../pages/basic/prohibitive")),
         },
         {
            exact: true,
            path: "/prohibitive/submit",
            visible: false,
            component: loadable(
               () => import("../pages/basic/prohibitive/Submit")
            ),
         },
         {
            exact: true,
            path: "/password",
            sidebar: {
               label: "패스워드 관리",
            },
            component: loadable(() => import("../pages/basic/Password")),
         },
         {
            exact: true,
            path: "/beo-user",
            sidebar: {
               label: "BEO 회원 리스트",
            },
            component: loadable(() => import("../pages/basic/beo")),
         },
         {
            exact: true,
            path: "/beo-user/submit",
            visible: false,
            component: loadable(() => import("../pages/basic/beo/Submit")),
         },
         {
            exact: true,
            path: "/grade",
            sidebar: {
               label: "등급별 권환 조회",
            },
            component: loadable(() => import("../pages/basic/Grade")),
         },
         {
            exact: true,
            path: "/grade/:gradeId",
            visible: false,
            component: loadable(() => import("../pages/basic/Grade/Submit")),
         },
      ],
   },
   {
      exact: true,
      path: "/user",
      sidebar: {
         icon: <UserOutlined />,
         label: "회원 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "회원 리스트",
            },
            component: loadable(() => import("../pages/user")),
         },
         {
            exact: true,
            visible: false,
            path: "/submit",
            component: loadable(() => import("../pages/user/Submit")),
         },
         {
            exact: true,
            visible: false,
            path: "/:userId",
            component: loadable(() => import("../pages/user/userDetail")),
         },
      ],
   },
   {
      exact: true,
      path: "/home",
      sidebar: {
         icon: <HomeOutlined />,
         label: "HOME 관리",
      },
      children: [
         {
            exact: true,
            path: "/section",
            sidebar: {
               label: "섹션 리스트",
            },
            component: loadable(() => import("../pages/home/section")),
         },
         {
            exact: true,
            path: "/section/submit",
            visible: false,
            component: loadable(() => import("../pages/home/section/Submit")),
         },
         {
            exact: true,
            path: "/section/contents",
            visible: false,
            component: loadable(() => import("../pages/home/section/contents")),
         },
         {
            exact: true,
            path: "/section/contents/submit",
            visible: false,
            component: loadable(
               () => import("../pages/home/section/contents/Submit")
            ),
         },
         {
            exact: true,
            path: "/banner",
            sidebar: {
               label: "배너 관리",
            },
            children: [
               {
                  exact: true,
                  path: "",
                  sidebar: {
                     label: "상단 배너 리스트",
                  },
                  component: loadable(() => import("../pages/home/banner/top")),
               },
               {
                  exact: true,
                  path: "/submit",
                  visible: false,
                  component: loadable(
                     () => import("../pages/home/banner/top/Submit")
                  ),
               },
               {
                  exact: true,
                  path: "/sub",
                  sidebar: {
                     label: "서브 배너 리스트",
                  },
                  component: loadable(() => import("../pages/home/banner/sub")),
               },
               {
                  exact: true,
                  path: "/sub/submit",
                  visible: false,
                  component: loadable(
                     () => import("../pages/home/banner/sub/Submit")
                  ),
               },
            ],
         },
         {
            exact: true,
            path: "/description",
            sidebar: {
               label: "고정 영역 설명 관리",
            },
            component: loadable(() => import("../pages/home/description")),
         },
         {
            exact: true,
            path: "/description/submit",
            visible: false,
            component: loadable(
               () => import("../pages/home/description/Submit")
            ),
         },
      ],
   },
   {
      exact: true,
      path: "/live",
      sidebar: {
         icon: <FundProjectionScreenOutlined />,
         label: "LIVE 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "LIVE 리스트",
            },
            component: loadable(() => import("../pages/live")),
         },
         {
            exact: true,
            path: "/detail",
            visible: false,
            component: loadable(() => import("../pages/live/Detail")),
         },
         {
            exact: true,
            path: "/submit",
            visible: false,
            component: loadable(() => import("../pages/live/Submit")),
         },
         {
            exact: true,
            path: "/speaker",
            visible: false,
            component: loadable(() => import("../pages/live/speaker")),
         },
         {
            exact: true,
            path: "/speaker/submit",
            visible: false,
            component: loadable(() => import("../pages/live/speaker/Submit")),
         },
         {
            exact: true,
            path: "/quiz",
            visible: false,
            component: loadable(() => import("../pages/live/quiz")),
         },
         {
            exact: true,
            path: "/quiz/submit",
            visible: false,
            component: loadable(() => import("../pages/live/quiz/Submit")),
         },
         {
            exact: true,
            path: "/vote",
            visible: false,
            component: loadable(() => import("../pages/live/vote")),
         },
         {
            exact: true,
            path: "/vote/submit",
            visible: false,
            component: loadable(() => import("../pages/live/vote/Submit")),
         },
         {
            exact: true,
            path: "/vote/choice",
            visible: false,
            component: loadable(() => import("../pages/live/vote/choice")),
         },
         {
            exact: true,
            path: "/vote/choice/submit",
            visible: false,
            component: loadable(
               () => import("../pages/live/vote/choice/Submit")
            ),
         },
         {
            exact: true,
            path: "/history/:id",
            visible: false,
            component: loadable(() => import("../pages/live/History")),
         },
      ],
   },
   {
      exact: true,
      path: "/series",
      sidebar: {
         icon: <UserOutlined />,
         label: "ORIGINAL 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "ORIGINAL 리스트",
            },
            component: loadable(() => import("../pages/series")),
         },
         {
            exact: true,
            path: "/submit",
            visible: false,
            component: loadable(() => import("../pages/series/Submit")),
         },
         {
            exact: true,
            path: "/reply",
            visible: false,
            component: loadable(() => import("../pages/series/reply")),
         },
         {
            exact: true,
            path: "/review",
            visible: false,
            component: loadable(() => import("../pages/series/review")),
         },
         {
            exact: true,
            path: "/viewer",
            visible: false,
            component: loadable(() => import("../pages/series/viewer")),
         },
         {
            exact: true,
            path: "/episode",
            visible: false,
            component: loadable(() => import("../pages/series/episode")),
         },
         {
            exact: true,
            path: "/episode/submit",
            visible: false,
            component: loadable(() => import("../pages/series/episode/Submit")),
         },
         {
            exact: true,
            path: "/section",
            sidebar: {
               label: "섹션 리스트",
            },
            component: loadable(() => import("../pages/series/section")),
         },
         {
            exact: true,
            path: "/section/submit",
            visible: false,
            component: loadable(() => import("../pages/series/section/Submit")),
         },
         {
            exact: true,
            path: "/section/contents",
            visible: false,
            component: loadable(
               () => import("../pages/series/section/contents")
            ),
         },
         {
            exact: true,
            path: "/section/contents/submit",
            visible: false,
            component: loadable(
               () => import("../pages/series/section/contents/Submit")
            ),
         },
         {
            exact: true,
            path: "/banner",
            sidebar: {
               label: "배너 리스트",
            },
            component: loadable(() => import("../pages/series/banner")),
         },
         {
            exact: true,
            path: "/banner/submit",
            visible: false,
            component: loadable(() => import("../pages/series/banner/Submit")),
         },
      ],
   },
   {
      exact: true,
      path: "/vod",
      sidebar: {
         icon: <VideoCameraOutlined />,
         label: "VOD 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "VOD 리스트",
            },
            component: loadable(() => import("../pages/vod")),
         },
         {
            exact: true,
            path: "/submit",
            visible: false,
            component: loadable(() => import("../pages/vod/Submit")),
         },
         {
            exact: true,
            path: "/reply",
            visible: false,
            component: loadable(() => import("../pages/vod/reply")),
         },
         {
            exact: true,
            path: "/review",
            visible: false,
            component: loadable(() => import("../pages/vod/review")),
         },
         {
            exact: true,
            path: "/viewhistory",
            visible: false,
            component: loadable(() => import("../pages/vod/ViewHistory")),
         },
         {
            exact: true,
            path: "/section",
            sidebar: {
               label: "섹션 리스트",
            },
            component: loadable(() => import("../pages/vod/section")),
         },
         {
            exact: true,
            path: "/section/submit",
            visible: false,
            component: loadable(() => import("../pages/vod/section/Submit")),
         },
         {
            exact: true,
            path: "/section/contents",
            visible: false,
            component: loadable(() => import("../pages/vod/section/contents")),
         },
         {
            exact: true,
            path: "/section/contents/submit",
            visible: false,
            component: loadable(
               () => import("../pages/vod/section/contents/Submit")
            ),
         },
         {
            exact: true,
            path: "/banner",
            sidebar: {
               label: "배너 리스트",
            },
            component: loadable(() => import("../pages/vod/banner")),
         },
         {
            exact: true,
            path: "/banner/submit",
            visible: false,
            component: loadable(() => import("../pages/vod/banner/Submit")),
         },
      ],
   },
   {
      exact: true,
      path: "/life",
      sidebar: {
         icon: <CoffeeOutlined />,
         label: "LIFE 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "LIFE 리스트",
            },
            component: loadable(() => import("../pages/life")),
         },
         {
            exact: true,
            visible: false,
            path: "/submit",
            component: loadable(() => import("../pages/life/Submit")),
         },
         {
            exact: true,
            path: "/category",
            sidebar: {
               label: "카테고리 리스트",
            },
            component: loadable(() => import("../pages/life/category")),
         },
         {
            exact: true,
            visible: false,
            path: "/category/submit",
            component: loadable(() => import("../pages/life/category/Submit")),
         },
      ],
   },
   {
      exact: true,
      path: "/alarm",
      sidebar: {
         icon: <BellOutlined />,
         label: "알림 관리",
      },
      children: [
         {
            exact: true,
            path: "",
            sidebar: {
               label: "알림 내역 조회",
            },
            component: loadable(() => import("../pages/alarm")),
         },
         {
            exact: true,
            visible: false,
            path: "/submit",
            component: loadable(() => import("../pages/alarm/Submit")),
         },
      ],
   },
   {
      exact: true,
      path: "/service",
      sidebar: {
         icon: <QuestionCircleOutlined />,
         label: "고객센터",
      },
      children: [
         {
            exact: true,
            path: "/faq",
            sidebar: {
               label: "FAQ 리스트",
            },
            component: loadable(() => import("../pages/serviceCenter/faq")),
         },
         {
            exact: true,
            visible: false,
            path: "/faq/submit",
            component: loadable(
               () => import("../pages/serviceCenter/faq/Submit")
            ),
         },
         {
            exact: true,
            path: "/qna",
            sidebar: {
               label: "1:1 문의 리스트",
            },
            component: loadable(() => import("../pages/serviceCenter/qna")),
         },
         {
            exact: true,
            visible: false,
            path: "/qna/submit",
            component: loadable(
               () => import("../pages/serviceCenter/qna/Submit")
            ),
         },
         {
            exact: true,
            path: "/wrong",
            sidebar: {
               label: "신고 리스트",
            },
            component: loadable(() => import("../pages/serviceCenter/wrong")),
         },
      ],
   },
];
