import React from 'react';
import {
  RecoilRoot,
  MutableSnapshot,
  // @ts-ignore
  useTransactionObservation_UNSTABLE as useTransactionObservation, // eslint-disable-line
} from 'recoil';

import { accessTokenState, refreshTokenState } from './auth';

const PERSISTENT_ATOMS = [accessTokenState, refreshTokenState];

const initializeState = ({ set }: MutableSnapshot) => {
  PERSISTENT_ATOMS.forEach((atom) => {
    const persistedState = localStorage.getItem(atom.key);
    if (persistedState) {
      set(atom, JSON.parse(persistedState).value);
    }
  });
};

function PersistenceObserver() {
  useTransactionObservation(({ atomValues, modifiedAtoms }: any) => {
    for (const modifiedAtom of modifiedAtoms) {
      localStorage.setItem(
        modifiedAtom,
        JSON.stringify({ value: atomValues.get(modifiedAtom) })
      );
    }
  });
  return null;
}

const PersistentRecoil: React.FC = ({ children }) => {
  return (
    <RecoilRoot initializeState={initializeState}>
      <PersistenceObserver />
      {children}
    </RecoilRoot>
  );
};

export default PersistentRecoil;
